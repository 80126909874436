<template>
  <div class="container-center-horizontal">
    <div class="qr1 screen">
      <div class="overlap-group2">
        <div class="vertical-stories">
          <div class="stories-2">
            <img class="rectangle-11" src="@/assets/rectangle-26@1x.png" />
            <router-link to="scan">
              <div class="overlap-group">
                <div class="your-image-here custom-button">
                  <!-- <div class="group-12">
                    <div class="group-11">
                      <img class="group-52" src="@/assets/group-52@1x.png" />
                    </div>
                  </div>-->
                </div>
                <div class="text">
                  <div class="text-22 raleway-black-torea-bay-40px">
                    <i18n path="global.title" />
                  </div>
                  <div class="nkoaissolis">
                    <div>
                      <i18n path="global.next11" />
                    </div>
                    <div>
                      <i18n path="global.next12" />
                    </div>
                  </div>
                </div>
                <div class="text-23 cormorantgaramond-normal-torea-bay-75px">
                  <div>
                    <i18n path="global.line11" />
                  </div>
                  <div>
                    <i18n path="global.line12" />
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <Footer />
        <div class="graphic-elements"></div>
        <router-link to="/scan">
          <img class="path-2" src="@/assets/path-2@1x.png" />
        </router-link>
        <div class="group">
          <div class="surname raleway-black-wild-sand-38px">
            <i18n path="global.solis1" />
          </div>
          <div class="text-26 raleway-normal-white-34px">
            <div>
              <i18n path="prescan.line1" />
            </div>
            <div>
              <i18n path="prescan.line2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  name: "Prescan",
  components: { Footer },
};
</script>

<style scoped>
/* screen - qr1 */

.qr1 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  overflow: hidden;
  width: 1080px;
}

.qr1 .overlap-group2 {
  height: 1920px;
  position: relative;
  width: 1081px;
}

.qr1 .vertical-stories {
  cursor: pointer;
  display: flex;
  height: 1920px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 1080px;
}

.qr1 .stories-2 {
  background-color: var(--link-water);
  height: 1920px;
  width: 1080px;
}

.qr1 .rectangle-11 {
  height: 144px;
  left: 17px;
  object-fit: cover;
  position: absolute;
  top: 66px;
  width: 144px;
}

.qr1 .overlap-group {
  height: 1563px;
  left: 121px;
  position: absolute;
  top: 168px;
  width: 900px;
}

.qr1 .your-image-here {
  background-image: url(~@/assets/Roka-ar-telefonu.png);
  background-color: var(--perano);
  background-position: 50% 50%;
  background-size: cover;
  height: 815px;
  /* left: 121px; */
  min-width: 657px;
  /* padding: 142.5px 161.3px; */
  position: absolute;
  /* top: 916px; */
  /* height: 815px; */
  /* left: 0; */
  /* position: absolute; */
  top: 748px;
  /* width: 657px; */
}

.qr1 .group-12 {
  display: flex;
  flex: 1;
  width: 657px;
}

.qr1 .group-11 {
  align-items: flex-end;
  background-color: var(--perano);
  display: flex;
  height: 815px;
  padding: 202.8px 124.1px;
  width: 657px;
}

.qr1 .group-52 {
  height: 408px;
  object-fit: cover;
  width: 408px;
}

.qr1 .text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 71px;
  min-height: 1002px;
  position: absolute;
  top: 0;
  width: 830px;
}

.qr1 .text-22 {
  letter-spacing: 10.4px;
  line-height: 50px;
  margin-top: -2px;
  min-height: 50px;
  min-width: 686px;
  text-align: center;
  white-space: nowrap;
}

.qr1 .nkoaissolis {
  align-self: flex-end;
  color: var(--torea-bay);
  font-family: var(--font-family-cormorant_garamond);
  font-size: 50px;
  font-style: italic;
  letter-spacing: 1.25px;
  line-height: 48px;
  margin-top: 851px;
  min-height: 110px;
  min-width: 162px;
  white-space: nowrap;
}

.qr1 .text-23 {
  /* left: 139px; */
  letter-spacing: 0;
  line-height: 64px;
  position: absolute;
  text-align: center;
  top: 129px;
  white-space: nowrap;
}

.qr1 .component-1-3 {
  align-items: flex-end;
  display: flex;
  height: 118px;
  left: 0;
  min-width: 1080px;
  position: absolute;
  top: 1802px;
}

.qr1 .overlap-group1 {
  height: 120px;
  margin-bottom: -2px;
  position: relative;
  width: 1080px;
}

.qr1 .rectangle-30 {
  background-color: var(--link-water);
  height: 118px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.qr1 .text-24 {
  left: 184px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.qr1 .text-25 {
  left: 609px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 13px;
  white-space: nowrap;
}

.qr1 .copyright {
  left: 447px;
  letter-spacing: 0.1px;
  line-height: 64px;
  position: absolute;
  top: 56px;
  white-space: nowrap;
}

.qr1 .line-1 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1080px;
}

.qr1 .graphic-elements {
  background-color: var(--torea-bay);
  height: 374px;
  left: 320px;
  position: absolute;
  top: 619px;
  width: 637px;
}

.qr1 .path-2 {
  cursor: pointer;
  height: 67px;
  left: 862px;
  object-fit: cover;
  position: absolute;
  top: 1206px;
  width: 63px;
}

.qr1 .group {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 401px;
  min-height: 303px;
  position: absolute;
  top: 657px;
  width: 465px;
}

.qr1 .surname {
  letter-spacing: 9.5px;
  line-height: 38px;
  margin-top: 4px;
  min-height: 47px;
  min-width: 218px;
  white-space: nowrap;
}

.qr1 .text-26 {
  letter-spacing: 2.72px;
  line-height: 34px;
  margin-top: 12px;
  min-height: 242px;
  text-align: center;
  width: 465px;
}
</style>